.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.border-bottom{
  border-bottom: 1px solid rgb(219, 218, 222)
}
.cross-icon {
  box-shadow: 0px 2px 4px 0px rgba(165, 163, 174, 0.30)
}

.custom-scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.custom-scroll::-webkit-scrollbar-track {
  background: transparent; 
}
.custom-scroll::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 4px;
}
.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
